import React from 'react'
import * as Icons from 'react-icons/fi'
import '../styles/pages/about.scss'
import CallToAction from '../components/CallToAction'
import { BiChevronsRight } from 'react-icons/bi'
import { routes } from '../routes'
import { capitalize, colors } from '../scripts/helpers'


const About = () => {

  return (
    <div id="about" className="about">
      <h1 className="h1">à propos</h1>
      <p className="subtitle">Enchanté !</p>
      <div className="about__content">
        <div className="about__content__left">
          <p style={{ marginBottom: '2vh' }}>Je m'appelle Enzo Avagliano et je suis développeur web Freelance.</p>
          <p style={{ marginBottom: '2vh' }}>Mon métier est avant tout une passion qui m'anime depuis plus de 8 ans et à laquelle je consacre tout mon temps. Construire un site internet à partir d'une idée, d'une vision est pour moi un réel plaisir, et c'est de cette manière que je vous propose un travail de qualité. Chaque projet est un nouveau défi, unique et fourni avec son lot d'apprentissages et de connaissances.</p>
          <p>Je suis à votre disposition pour réaliser un site adapté à vos besoins et votre budget.</p>
          <div className="link-container">
            <p>Vous avez un projet ? Découvrez mes services</p>
            <CallToAction
              route={routes.services.path}
              title={capitalize(routes.services.name)}
              icon={<BiChevronsRight style={{ fill: colors.white }} />}
            />
          </div>
        </div>
        <div className="about__content__right">
          <img src="/img/pp_500.jpg" alt="Enzo Avagliano" />
        </div>
      </div>
    </div>
  )
}

export default About