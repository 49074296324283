import React from 'react'
import * as Icons from 'react-icons/bi'
import '../styles/pages/services.scss'
import CallToAction from '../components/CallToAction'
import { routes } from '../routes'
import { capitalize, colors } from '../scripts/helpers'

const Services = () => {
  return (
    <div id="services" className="services">
      <h1 className="h1 title">Mes services</h1>
      <p className="subtitle">Mes compétences au service de votre projet</p>
      <div className="content">
        <div className="left">
          <p>Chaque projet est unique, chaque projet a des besoins différents, mais chaque projet mérite une qualité  et une attention hors-normes</p>
          <p style={{ marginTop: '2vh' }}>Que vous soyez artisant, TPE, PME, grand groupe ou que vous souhaitez simplement disposer d’une visibilité sur le Web, faites le choix d’un site construit sur-mesure.</p>

          <div className="cta-container">
            <p>Découvrez mon parcours, ainsi que mes projets !</p>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <CallToAction
                route={routes.projects.path}
                title={capitalize(routes.projects.name)}
                icon={<Icons.BiChevronsRight style={{ fill: colors.white }} />}
              />
              <CallToAction
                route={routes.curriculum.path}
                title={capitalize(routes.curriculum.name)}
                icon={<Icons.BiTargetLock style={{ fill: colors.white }} />}
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="service">
            <h3><Icons.BiLoader /> Faites parler de vous !</h3>
            <p>Sublimez votre image de marque avec un site web moderne et pertinant. Grâce à une solution sur-mesure.</p>
          </div>
          <div className="service">
            <h3><Icons.BiLoader /> Retenez l’attention!</h3>
            <p>Impactez votre clientèle avec des contenus interactifs, personnalisés et adaptés à tous types d'appareils.</p>
          </div>
          <div className="service">
            <h3><Icons.BiLoader /> Augmentez vos revenus</h3>
            <p>Grâce au reférencement naturel, votre site web apparaitra toujours en premier et attirera de nouveaux clients pour votre business.</p>
          </div>
          <div className="service">
            <h3><Icons.BiLoader /> Partagez votre activité</h3>
            <p>Votre site web est votre vitrine, mettez en valeur vos produits.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services