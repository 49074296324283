export const capitalize = (str: string): string => {
  return str[0].toUpperCase() + str.slice(1);
}

export const colors = {
  'black': '#000000',
  'white': '#ffffff',
  'red': '#9c1d40',
  'green': '#006633',
  'grey': '#404040',
}