import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { colors } from '../scripts/helpers'
import '../styles/components/callToAction.scss'

interface CallToActionProps {
  route: string
  title: string
  color?: string
  disabled?: boolean
  icon?: ReactNode
}

export default function CallToAction({ route, title, color, disabled, icon }: CallToActionProps) {
  return (
    <Link className="cta" style={{ backgroundColor: colors.grey }} to={route}>{title} {icon && icon}</Link>
  )
}
