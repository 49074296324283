import React, { LegacyRef, useRef, useState } from 'react'
import { SpinnerCircular } from 'spinners-react'
import * as Icons from 'react-icons/fi'
import emailjs from '@emailjs/browser'
import '../styles/pages/contact.scss'


const Contact = () => {

  const formRef = useRef<HTMLFormElement>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true)


    emailjs.sendForm('Website', 'contact_template', formRef.current as HTMLFormElement, 'user_OimdLZV4uZQJjsxfr0Cgc')
      .then((result) => {
        formRef.current?.reset()
        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 3000)
        console.log("Mail :", result.text);
      }, (error) => {
        setLoading(false)
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 3000)
        console.log("Mail :", error.text);
      });
  }

  return (
    <div id="contact">
      <h1>Me contacter</h1>
      <p className="subtitle">Je reste disponible à tout moment !</p>
      <div className="content">
        <div className="left">
          <form ref={formRef as LegacyRef<HTMLFormElement>} onSubmit={sendEmail} style={{ marginTop: '5vh', width: '80%' }}>
            <div className="form-group">
              <label htmlFor="name">Nom</label>
              <input type="text" id="name" name='name' placeholder='Votre nom' />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name='email' placeholder='Votre adresse mail' />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" name='message' placeholder='Votre message' rows={10} />
            </div>
            <button disabled={loading} type="submit">{loading && <SpinnerCircular color={"#F7F7F7"} size={20} style={{ marginRight: '1rem' }} />} Envoyer</button>
            {error && <p className="error">Une erreur est survenue, veuillez réessayer...</p>}
            {success && <p className="success">Votre message a bien été envoyé !</p>}
          </form>
        </div>
        <div className="right">
          <div>
            <p className="title">Vous me trouverez aussi sur :</p>
            <div className="socials">
              <a href="https://www.linkedin.com/in/enzo-avagliano/" target={"_blank"} rel={"noreferrer"} className="link"><Icons.FiLink /> Linkedin</a>
              <a href="https://github.com/EloxFire" target={"_blank"} rel={"noreferrer"} className="link"><Icons.FiLink /> Github</a>
              <a href="https://twitter.com/EloxFire" target={"_blank"} rel={"noreferrer"} className="link"><Icons.FiLink /> Twitter</a>
              <a href="https://www.malt.fr/profile/enzoavagliano" target={"_blank"} rel={"noreferrer"} className="link"><Icons.FiLink /> Malt</a>
            </div>
          </div>
          <div className="number-container">
            <p className="title">Vous pouvez aussi m'appeller :</p>
            <a className="number link" href="tel:+33669074259"><Icons.FiPhoneCall style={{ marginRight: '1rem' }} />06.69.07.42.59</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact