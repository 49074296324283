import React, { ReactNode } from 'react'
import Navbar from './Navbar'
import '../styles/components/layout.scss'

interface LayoutProps {
  component: ReactNode
}

export default function Layout({ component }: LayoutProps) {
  return (
    <div id="layout">
      <Navbar />
      {component}
    </div>
  )
}
