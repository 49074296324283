import React from 'react'
import { WebsiteCarbonBadge } from 'react-websitecarbon-badge';
import CallToAction from '../components/CallToAction';
import '../styles/pages/home.scss'
import { BiChevronsRight } from 'react-icons/bi'
import { routes } from '../routes';
import { capitalize, colors } from '../scripts/helpers';

const Home = () => {

  return (
    <div id="home" className="home">
      <div className="home__content">
        <h2 className="h1 title">Enzo Avagliano</h2>
        <h1 className="subtitle">Développeur Web Freelance</h1>
        <div className="home__content__cta">
          <CallToAction
            route={routes.about.path}
            title={capitalize(routes.about.name)}
            icon={<BiChevronsRight style={{ fill: colors.white }} />}
          />
        </div>
        <div className="home__content__catch-phrase">
          <h3 className="catch-phrase">Création de sites et d'applications web sur-mesure</h3>
          <p>Un projet, une idée ? <span >Parlons-en !</span></p>
        </div>
      </div>
      <div className="carbon-badge">
        <WebsiteCarbonBadge url="https://enzoavagliano.fr" lang='fr' />
      </div>
    </div>
  )
}

export default Home