import React from 'react'
import * as Icons from 'react-icons/fi'
import '../styles/pages/notFound.scss'


const NotFound = () => {
  return (
    <div id="notFound">
      <h1>Erreur 404</h1>
      <h2>Page introuvable</h2>
      <p><Icons.FiLink /> Retour à l'accueil</p>
    </div>
  )
}

export default NotFound