import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { routes } from '../routes'
import '../styles/components/navbar.scss'

export default function Navbar() {

  const [mobileClick, setMobileClick] = useState(false)
  const location = useLocation()

  // Add event listener when mobileClick is true
  useEffect(() => {

    if (mobileClick) {
      window.addEventListener('scroll', preventDefault);
    } else {
      window.removeEventListener('scroll', preventDefault);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', preventDefault);
    };
  }, [mobileClick, location.pathname]);

  // Prevent the default scroll behavior
  function preventDefault(e: Event) {
    e.preventDefault();
  }

  return (
    <div className="navbar">
      <div className="navbar__logo">
        <Link className="home-link" to={routes.home.path}>
          <img src="/img/logos/logo_black.svg" alt="Logo de Enzo Avagliano" />
        </Link>
      </div>
      <div className="navbar__links">
        <Link className={`link ${location.pathname === routes.home.path && "active"}`} to={routes.home.path}>{routes.home.name}</Link>
        <Link className={`link ${location.pathname === routes.about.path && "active"}`} to={routes.about.path}>{routes.about.name}</Link>
        <Link className={`link ${location.pathname === routes.services.path && "active"}`} to={routes.services.path}>{routes.services.name}</Link>
        <Link className={`link ${location.pathname === routes.projects.path && "active"}`} to={routes.projects.path}>{routes.projects.name}</Link>
        <Link className={`link ${location.pathname === routes.contact.path && "active"}`} to={routes.contact.path}>{routes.contact.name}</Link>
      </div>
      <div className="navbar__burger">
        <p className="h3" onClick={() => setMobileClick(true)}>&#9776;</p>
      </div>
      <div className={`navbar__mobile ${mobileClick && 'navbar__mobile__active'}`}>
        <p className="h2" style={{ marginBottom: '10vh' }}>Enzo Avagliano</p>
        <Link className="link" to={routes.home.path} onClick={() => setMobileClick(false)}>{routes.home.name}</Link>
        <Link className="link" to={routes.about.path} onClick={() => setMobileClick(false)}>{routes.about.name}</Link>
        <Link className="link" to={routes.services.path} onClick={() => setMobileClick(false)}>{routes.services.name}</Link>
        <Link className="link" to={routes.projects.path} onClick={() => setMobileClick(false)}>{routes.projects.name}</Link>
        <Link className="link" to={routes.contact.path} onClick={() => setMobileClick(false)}>{routes.contact.name}</Link>
        <p className="link" onClick={() => setMobileClick(false)}>Fermer</p>
      </div>
    </div>
  )
}
