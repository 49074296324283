import React from 'react'
import Project from '../components/Project'
import '../styles/pages/projects.scss'


const Projects = () => {

  return (
    <div id="projects" className="projects">
      <h1 className="h1 title">Mes projets</h1>
      <p className="subtitle">Quelques projets notables</p>
      <div className="content">
        <Project
          name="Astroshare"
          type="Site internet"
          image="astroshare"
          technos={["ReactJS", "THREE.JS"]}
          link="https://astroshare.fr"
        />
        <Project
          name="ITI Medics"
          type="Application Mobile & Site internet"
          image="iti"
          technos={["React Native", "NodeJS", "Firebase"]}
          link="https://itimedics.fr"
        />
        <Project
          name="IGB Planner"
          type="Application Web"
          image="igb"
          technos={["ReactJS", "NodeJS", "Firebase"]}
          link="https://igbcrew.fr"
        />
        <Project
          name="Visionn'air"
          type="Application Mobile"
          image="visionnair"
          technos={["React Native", "NodeJS"]}
          link="https://play.google.com/store/apps/details?id=fr.eloxfire.visionnair"
        />
      </div>
    </div>
  )
}

export default Projects