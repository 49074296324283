export const routes = {
  home: {
    path: '/',
    name: 'accueil',
  },
  about: {
    path: '/about',
    name: 'à propos',
  },
  services: {
    path: '/services',
    name: 'services',
  },
  projects: {
    path: '/projects',
    name: 'projets',
  },
  contact: {
    path: '/contact',
    name: 'contact',
  },
  legal: {
    path: '/legal',
    name: 'mentions légales',
  },
  curriculum: {
    path: '/curriculum',
    name: 'Parcours',
  },
  notFound: {
    path: '*',
    name: '404',
  },
}