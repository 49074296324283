import { Link } from 'react-router-dom'
import '../styles/components/project.scss'

interface ProjectProps {
  name: string,
  type: string,
  image: string,
  technos: string[],
  link: string,
}

const Project = ({ name, type, image, technos, link }: ProjectProps) => {
  return (
    <div className="project">
      <a className="project__header" href={link} target='_blank' rel='noopener noreferrer'>
        <img src={`/img/illustrations/${image}_banner.svg`} alt={name} />
      </a>
      <div className="project__body">
        <p className="project__body__title">{name}</p>
        <p className="project__body__type">{type}</p>
        <div className="project__body__technos">
          {technos.map(techno => <span className="project__body__technos__badge" key={techno}>{techno}</span>)}
        </div>
      </div>
    </div>
  )
}

export default Project