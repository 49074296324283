import React from 'react'
import About from './pages/About';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Services from './pages/Services';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Layout from './components/Layout';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { routes } from './routes';
import './styles/app.scss';
import './styles/components/header.scss';


function App() {
  return (
    <Router>
      <Routes>
        <Route path={routes.home.path} element={<Layout component={<Home />} />} />
        <Route path={routes.about.path} element={<Layout component={<About />} />} />
        <Route path={routes.services.path} element={<Layout component={<Services />} />} />
        <Route path={routes.projects.path} element={<Layout component={<Projects />} />} />
        <Route path={routes.contact.path} element={<Layout component={<Contact />} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
